import React from 'react';
import logo from './logo.svg';
import cv from './profile.png';
import './App.css';
import Home from './components/Home';
import { Link } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <h1> Jessica Jeanne Elliott</h1>
        <img src={cv} className="App-logo" />
        <h3> Software Engineer | Linguist | Audio Engineer </h3>
        <Link to="/files/cv.pdf" target="_blank" download>download general CV</Link>

        <p>
          I have a BSc Honours degree in Computer Science, 2 years of research experience, 3 years of industry experience
          working for Amazon Web Services in dev/ops, and 6 months for a solutions company as a full stack web developer.
          I have also done training and work as an audio engineer.
        </p>
        <p>
          My honours thesis project was on annotation systems for machine translation of sign languages, and my masters
          thesis project (not completed) involved audio networking using the XMOS AVB boards as a hardware plug-in for audio
          processing.
        </p>
        <p>
          My interests are in health (particularly mental health), audio, linguistics, childhood development, data science,
          and hardware interfacing.
        </p>
      </header>
    </div>
  );
}

export default App;
